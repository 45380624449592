import React from 'react';

import { IconProps } from '@src/types';

export default function MobileCartIcon(props: IconProps) {
	const { width, height, style } = props;

	return (
		<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		viewBox="0 0 24 24"
		{...props}
		>
		<path
			fill="#1C1C1C"
			d="M2.439 4.003a.428.428 0 00-.291.101.417.417 0 00.193.728l1.88.001.409 1.916v.004l2.027 9.043a.427.427 0 00.415.328h11.476a.439.439 0 00.305-.12.426.426 0 00.094-.461.437.437 0 00-.234-.226.406.406 0 00-.165-.03H7.414l-.391-1.747h12.104a.422.422 0 00.416-.328l1.447-6.46a.413.413 0 00-.084-.352.438.438 0 00-.147-.116.432.432 0 00-.184-.04c-5.062 0-10.123 0-15.185-.003l-.375-1.904a.422.422 0 00-.325-.323l-2.143-.011c-.028-.007-.08 0-.108 0zm3.135 3.076c4.824.003 9.648.003 14.471.003l-1.26 5.62H6.836L5.574 7.08zm4.219 9.93c-.832 0-1.515.675-1.515 1.495 0 .82.683 1.496 1.515 1.496a1.51 1.51 0 001.514-1.495c0-.82-.682-1.496-1.514-1.496zm6.098 0a1.51 1.51 0 00-1.514 1.495c0 .82.682 1.496 1.514 1.496.833 0 1.515-.675 1.515-1.495 0-.82-.682-1.496-1.515-1.496zm-6.098.838c.37 0 .664.289.664.657 0 .37-.293.658-.664.658a.655.655 0 01-.664-.657c0-.37.293-.658.664-.658zm6.098 0c.371 0 .664.289.664.657 0 .37-.293.658-.664.658a.655.655 0 01-.664-.657c0-.37.293-.658.664-.658z"
		/>
		</svg>
	);
}
