import React from 'react';

import { IconProps } from '@src/types';

export default function MyPageIcon(props: IconProps) {
	const { width, height, style, fill } = props;
	return (
		<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		viewBox="0 0 24 24"
		{...props}
		>
		<path
			fill="#1C1C1C"
			fillRule="evenodd"
			d="M3 3.6a.4.4 0 00-.4.4v15a.4.4 0 00.4.4h17a.4.4 0 00.4-.4V6.02c-.052-.197-.238-.366-.285-.383l-.057-.013-.031-.003-.025-.002h-.044l-.146-.002a53.034 53.034 0 00-.541-.004l-1.927-.006L11.5 5.6h-.275l-.898-1.78a.402.402 0 00-.357-.219H3zm7.329 1.998L9.723 4.4H3.4v1.198h.33l1.926-.002c1.3 0 2.954 0 4.673.002zM3.399 18.6V6.398h.332l1.925-.002 5.843.003 5.844.008 1.924.006.333.002V18.6H3.4z"
			clipRule="evenodd"
		/>
		</svg>
	);
}
