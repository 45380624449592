import React from 'react';
import styled from 'styled-components';

import { Button, P } from '@src/components/atoms';
import LinkWrapper from '@src/components/molecules/link';
import { getCookie } from '@src/lib/util/cookie';
import { setLoginReferer } from '@src/lib/util/browser';
import MobileCartIcon from '@src/components/icons/common/cart/mobile';
import MyPageIcon from '@src/components/icons/common/mypage';

import { useRouter } from 'next/router';

export default function MobileRightButton() {
	const router = useRouter();
	const isLoggedIn = getCookie('isLoggedIn');
	const href = isLoggedIn ? '/mypage' : `/login?redirectTo=${router.asPath}`;
	const text = isLoggedIn ? '내 서재' : '로그인';
	return (
		<Wrapper>
			<LinkWrapper href="/cart">
				<Button width="0.24rem" height="0.24rem">
					<MobileCartIcon width="100%" height="100%" />
				</Button>
			</LinkWrapper>
			<LinkWrapper href={href} onClick={setLoginReferer} style={{fontSize:'0px'}}>
				<MyPageIcon width="0.24rem" height="0.24rem"/>
			</LinkWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 60px;
`;

const Text = styled(P)`
	padding: 0 0.04rem;
	background-image: linear-gradient(to top, #fff27a 0.1rem, transparent 0.1rem);
`;
