import React from 'react';

import { IconProps } from '@src/types';

export default function ArrowLeftIcon(props: IconProps) {
	return (
		<svg width={10} height={18} viewBox="0 0 10 18" {...props}>
			<path
				d="M9.544 1.536a.92.92 0 000-1.272.854.854 0 00-1.234 0l-7.854 8.1a.92.92 0 000 1.272l7.854 8.1a.854.854 0 001.234 0 .92.92 0 000-1.272L2.307 9l7.237-7.464z"
				fill="#000"
				fillRule="nonzero"
			/>
		</svg>
	);
}
