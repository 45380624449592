import React from 'react';

import { IconProps } from '@src/types';

export default function DrawerIcon({ width, height, style }: IconProps) {
	return (
		<svg {...{ width, height, style }} viewBox="0 0 24 24">
			<path stroke="#1C1C1C" strokeLinecap="round" d="M3.5 5.5h17" />
			<path
				stroke="#1C1C1C"
				strokeLinecap="round"
				strokeLinejoin="bevel"
				d="M3.5 9.5h17"
			/>
			<path stroke="#1C1C1C" strokeLinecap="round" d="M3.5 13.5h17m-17 4h17" />
		</svg>
	);
}
