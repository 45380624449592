import React, { useState } from 'react';
import Router from 'next/router';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import ArrowLeftIcon from '@src/components/icons/arrow/left';
import DrawerIcon from '@src/components/icons/drawer';
import buttonStyle from '@src/style/button';

const MainDrawer = dynamic(() => import('@src/modules/drawer/main'));
const MainDrawerContent = dynamic(() => import('../../drawer/main-content'));

function MobileLeftButton({ isBack }: { isBack: boolean }) {
	const [state, setState] = useState(false);

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			(event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') ||
			(event as React.KeyboardEvent).key === 'shift'
		) {
			return;
		}
		setState(open);
	};

	const handleBack = () => {
		Router.back();
	};

	if (isBack) {
		return (
			<Wrapper>
				<IconButton type="button" onClick={handleBack}>
					<ArrowLeftIcon height="0.14rem" />
				</IconButton>
			</Wrapper>
		);
	} else {
		return (
			<Wrapper>
				<IconButton onClick={toggleDrawer(true)}>
					<DrawerIcon height="0.24rem" />
				</IconButton>
				<MainDrawer {...{ state, toggleDrawer }} DrawerContent={MainDrawerContent} />
			</Wrapper>
		);
	}
}

export default React.memo(MobileLeftButton);

const Wrapper = styled.div`
	display: flex;
	width: 60px;
`;

const IconButton = styled.button`
	${buttonStyle.Default}
	justify-content: flex-start;
`;
