import React from 'react';
import styled from 'styled-components';

import { HeaderProps } from '..';
import { useScrollDirection } from '@src/hooks/util';
import cssMixin, { MOBILE_HEADER } from '@src/style';

import Left from './left';
import HeaderLogo from '../logo';
import Right from './right';

export type MobileHeaderProps = HeaderProps & {
	visibleAllDevice?: boolean;
};

function MobileHeader({ disableScroll, isBack, visibleAllDevice = false }: MobileHeaderProps) {
	const scrollDirection = useScrollDirection('down');

	return (
		<Background visibleAllDevice={visibleAllDevice}>
			<Wrapper isGoingUp={scrollDirection === 'up' && !disableScroll}>
				<Left isBack={isBack} />
				<HeaderLogo />
				<Right />
			</Wrapper>
		</Background>
	);
}

export default React.memo(MobileHeader);

const Background = styled.div<{ visibleAllDevice?: boolean }>`
	${props => !props.visibleAllDevice && `display:none;`};
	width: 100%;
	max-width: inherit;
	height: ${MOBILE_HEADER}px;
	${props => props.theme.breakpoint.labtop`
		display:flex;
	`}
`;

const Wrapper = styled.div<{ isGoingUp: boolean }>`
	${cssMixin.flexRow}
	justify-content:space-between;

	width: 100%;
	max-width: inherit;
	height: ${MOBILE_HEADER}px;

	margin: 0;
	padding: 0 16px;

	position: ${props => (props.isGoingUp ? 'fixed' : 'absolute')};
	top: 0;
	z-index: 100;

	background-color: white;
	box-shadow: 0 2px 4px 0 rgba(221, 221, 221, 0.5);
`;
